<template>
  <section class="sub-container" ref="sub-container">
    <sub-nav-with-user-info>
      <sub-nav></sub-nav>
    </sub-nav-with-user-info>
    <main class="sub">
      <router-view></router-view>
    </main>
  </section>
</template>

<script>
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import SubNav from 'Components/request/partials/SubNav'
export default {
  components: {
    SubNavWithUserInfo,
    SubNav,
  },
}
</script>

<style></style>
