<template>
  <div class="req-lnb-wrap">
    <ul>
      <li v-if="isEmployer">
        <router-link
          :class="{ on: routeName.indexOf('JobDocumentList') >= 0 }"
          to="/request"
        >
          <span class="ico"></span>
          <span class="txt">공고별</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ on: routeName.indexOf('request') >= 0 }"
          to="/requestList"
        >
          <span class="ico"></span>
          <span class="txt">근무요청</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ on: routeName.indexOf('attendance') >= 0 }"
          to="/attendance"
        >
          <span class="ico"></span>
          <span class="txt">출 &#183; 퇴근</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ on: routeName.indexOf('mypageCalcHistory') >= 0 }"
          to="/mypage/calc-history"
        >
          <span class="ico"></span>
          <span class="txt"> 정산</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style>
.req-lnb-wrap ul li:nth-child(3) a .ico {
  /* background: url(~assets/image/ico/ico_sm_04.svg) no-repeat 10px 50% / 18px; */
  background: url(~assets/image/ico/ico_sm_07.svg) no-repeat center center;
}
.req-lnb-wrap ul li:nth-child(3) a.on .ico {
  background: url(~assets/image/ico/ico_sm_07.on.svg) no-repeat center center;
}
</style>
